import React, { useState, useEffect, useCallback } from "react"
import { useRouter } from "next/router"
import { useSelector, useDispatch } from "react-redux"
import {
  Box,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Input,
  Paper,
  Typography,
  InputAdornment,
  IconButton,
  Link,
  TextField,
  ButtonGroup,
} from "@mui/material"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import ErrorOutline from "@mui/icons-material/ErrorOutline"
import Layout from "@/components/layouts/Layout"
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import { login } from "@/store/slice/auth"
import { enqueueSnackbar } from "@/store/slice/snackbar"
import { sendResetPasswordEmail } from "@/services/userService"
import { Dialog } from "@/components/overlays"
import { isValidEmail } from "@/utils/email"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Image from "next/legacy/image"

const REGISTER_BANNERS = [
  {
    title: "免費註冊，快速建立醫事人員及院所資訊主頁",
    image: "/admin/register-banner-01.svg",
    alt: "免費註冊，快速建立醫事人員及院所資訊主頁",
  },
  {
    title: "更快累積口碑好評，吸引更多新客前往",
    image: "/admin/register-banner-02.svg",
    alt: "更快累積口碑好評，吸引更多新客前往",
  },
  {
    title: "整合各大 HIS，民眾約診輕鬆數位化",
    image: "/admin/register-banner-03.svg",
    alt: "整合各大 HIS，民眾約診輕鬆數位化",
  },
]

const Login = () => {
  const router = useRouter()
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  })
  const { email, password } = inputs
  const [submitted, setSubmitted] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [resetPasswordEmail, setResetPasswordEmail] = useState("")
  const { isLoggedIn } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [errInput, setErrInput] = useState({})
  const [lockShown, setLockShown] = useState(false)

  const checkValidation = useCallback(() => {
    const errInput = {}
    if (!isValidEmail(resetPasswordEmail)) {
      errInput["resetPasswordEmail"] = "Email格式不正確"
    }
    setErrInput(errInput)
    return errInput
  }, [resetPasswordEmail])

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setInputs(inputs => ({ ...inputs, [name]: value }))
    setLockShown(lockShown ? false : true)
  }

  const handleSubmit = e => {
    e.preventDefault()

    setSubmitted(true)
    if (email && password) {
      dispatch(login({ username: email, password }))
        .unwrap()
        .then(() => {
          router.push(
            router.query.returnUrl || JSON.parse(localStorage.getItem("returnUrl")) || "/home"
          )
          localStorage.removeItem("returnUrl")

          dispatch(
            enqueueSnackbar({
              message: "登入成功！",
              options: {
                variant: "success",
              },
            })
          )
          return
        })
        .catch(error => {
          console.log("login page", error)
          setSubmitted(false)
          dispatch(
            enqueueSnackbar({
              message: "登入失敗！請輸入正確的帳號密碼",
              options: {
                variant: "error",
              },
            })
          )
        })
    } else {
      setSubmitted(false)
    }
  }

  const handleSendResetPasswordEmail = async () => {
    const hasError = Object.keys(checkValidation()).length > 0
    if (hasError) return
    try {
      await sendResetPasswordEmail(resetPasswordEmail)
      await dispatch(
        enqueueSnackbar({
          message: "重設密碼信已寄出。",
          options: {
            variant: "success",
          },
        })
      )
      setInputs(inputs => ({ ...inputs, password: "" }))
    } catch (error) {
      let message = "發生錯誤。"
      if (error === "user not found") {
        message = "無此用戶。"
      } else if (error === "reset password email already send") {
        message = "重設密碼信已寄出。"
      }
      dispatch(
        enqueueSnackbar({
          message,
          options: {
            variant: "error",
          },
        })
      )
    }
  }

  const SliderSettings = {
    dots: true,
    fade: true,
    lazyLoad: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: true,
  }

  useEffect(() => {
    if (Object.keys(errInput).length !== 0) {
      checkValidation(inputs)
    }
  }, [inputs]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // redirect to home if already logged in
    if (isLoggedIn) {
      router.push("/home")
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <Grid container component="main" sx={{ height: "calc(100vh - 120px)" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 120px)",
            display: { xs: "none", lg: "flex" },
          }}
        >
          <Typography
            component="h1"
            variant="headingH3Heavy"
            sx={{ marginBottom: "25px", color: "SHADES_900" }}
          >
            <Typography
              component="span"
              variant="headingH4Heavy"
              sx={{
                color: "PRIMARY_600",
              }}
            >
              加入PinMed
            </Typography>
            <br />
            讓流量成為走入診間的顧客
          </Typography>

          <Box
            sx={{
              margin: "0 auto",
              width: "85%",
              textAlign: "center",
              position: "relative",
            }}
          >
            <Slider {...SliderSettings}>
              {REGISTER_BANNERS.map((data, index) => (
                <div key={index}>
                  <Image alt={data.alt} src={data.image} width={375} height={334} />
                  <Typography
                    component="h2"
                    variant="headingH5Medium"
                    sx={{
                      color: "SHADES_500",
                      marginTop: "20px",
                      mb: "20px",
                    }}
                  >
                    {data.title}
                  </Typography>
                </div>
              ))}
            </Slider>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          sx={{
            m: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "SHADES_100",
            py: "50px",
            height: "calc(100vh - 110px)",
            justifyContent: "center",
            px: 2,
          }}
        >
          <Typography
            component="h2"
            variant="headingH3Heavy"
            sx={{
              mb: { xs: "0px", md: "25px" },
              color: "SHADES_900",
              display: { xs: "block", lg: "none" },
              fontSize: { xs: "33px", md: "40px" },
              lineHeight: "42.9px",
            }}
          >
            <Typography
              component="span"
              variant="headingH4Heavy"
              sx={{
                color: "PRIMARY_600",
                fontSize: { xs: "28px", md: "33px" },
              }}
            >
              加入PinMed
            </Typography>
            <br />
            讓流量成為走入診間的顧客
          </Typography>

          <ButtonGroup
            variant="outlined"
            aria-label="outlined button group"
            disableElevation
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "480px",
              width: "100%",
              mt: { xs: 2, sm: 4 },
            }}
          >
            <Button
              variant="outlined"
              component={Link}
              underline="none"
              href={"/account/login"}
              fullWidth
              sx={{
                color: "primary.contrastText",
                backgroundColor: "PRIMARY_600",
                overflow: "hidden",
                "&:hover": {
                  color: "primary.contrastText",
                  backgroundColor: "PRIMARY_600",
                },
              }}
            >
              登入
            </Button>
            <Button
              variant="contained"
              component={Link}
              underline="none"
              href={"/account/register"}
              fullWidth
              sx={{
                color: "PRIMARY_600",
                backgroundColor: "primary.contrastText",
                overflow: "hidden",
                "&:hover": {
                  color: "primary.contrastText",
                  backgroundColor: "PRIMARY_600",
                },
              }}
            >
              註冊
            </Button>
          </ButtonGroup>

          <Paper
            variant="outlined"
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ my: { xs: 2, sm: 4 }, p: { xs: 3, sm: 5 }, maxWidth: "480px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl>
                  <InputLabel htmlFor="email">帳號</InputLabel>
                  <Input
                    autoFocus
                    id="email"
                    name="email"
                    onChange={handleChange}
                    value={email}
                    placeholder="請輸入Email"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <InputLabel htmlFor="password">密碼</InputLabel>
                  <Input
                    id="password"
                    name="password"
                    type={passwordShown ? "text" : "password"}
                    onChange={handleChange}
                    value={password}
                    placeholder="請輸入密碼"
                    // placeholder="&emsp;&nbsp;&nbsp;請輸入密碼"
                    // sx={{
                    //   color: "SHADES_600",
                    // }}
                    // startAdornment={
                    //   <InputAdornment
                    //     sx={{ position: "absolute", left: 8, color: "SHADES_300" }}
                    //     position="start"
                    //   >
                    //     {lockShown ? <LockOutlinedIcon /> : ""}
                    //   </InputAdornment>
                    // }
                    endAdornment={
                      <InputAdornment sx={{ position: "absolute", right: 16 }} position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                          onMouseDown={event => {
                            event.preventDefault()
                          }}
                          edge="end"
                        >
                          {passwordShown ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                <Box sx={{ display: "flex", mt: 2 }}>
                  <Link
                    underline="none"
                    color="SHADES_400"
                    variant="headingSmallRegular"
                    onClick={() => setDialogOpen(true)}
                    sx={{ display: "flex", alignItems: "center", cursor: "pointer", gap: 1 }}
                  >
                    <ErrorOutline color="SHADES_400" fontSize="small" />
                    忘記密碼？
                  </Link>

                  <Dialog
                    open={dialogOpen}
                    title={"忘記密碼？"}
                    actions={
                      <>
                        <Button variant="outlined" onClick={() => setDialogOpen(false)}>
                          取消
                        </Button>
                        <Button
                          disabled={!resetPasswordEmail}
                          variant="contained"
                          onClick={handleSendResetPasswordEmail}
                        >
                          寄送重設密碼信
                        </Button>
                      </>
                    }
                  >
                    <Box sx={{ textAlign: "center", py: 2 }}>
                      請輸入你註冊時的 Email，我們會發送一封信件，點擊信件中的連結以重設密碼。
                    </Box>
                    <FormControl required error={!!errInput["resetPasswordEmail"]}>
                      <TextField
                        id="resetPasswordEmail"
                        name="resetPasswordEmail"
                        onChange={e => setResetPasswordEmail(e.target.value)}
                        value={resetPasswordEmail}
                        placeholder="請輸入Email"
                        error={errInput["resetPasswordEmail"]}
                        helperText={errInput["resetPasswordEmail"] || ""}
                      />
                    </FormControl>
                  </Dialog>
                </Box>
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, backgroundColor: "PRIMARY_600" }}
              disabled={submitted}
            >
              {submitted ? "驗證中..." : "登入"}
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Login
